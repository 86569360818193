/* ===================================
    About
====================================== */

/*
    Theme Name: dijiTribe
    Theme URI:
    Author: dijiTribe
    Author URI:
    Description: Multipage Website
 */

/* ===================================
    Google font
====================================== */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
$poppins: "Poppins", sans-serif;

/* ===================================
    General
====================================== */
body {
  font-family: $poppins;
  font-size: 14px;
  color: #202020;
  background: #ffffff;
  font-weight: 400;
  line-height: 25px;
  width: 100%;
  overflow-x: hidden;
}
h1, h2, h3, h4, h5 {
  line-height: 1.2;
  font-family: $poppins;
}
section,
.section-padding {
  position: relative;
  overflow-x: hidden;
}
a {
  color: inherit;
  text-decoration: none;
}
input:focus,
textarea:focus,
select {
  box-shadow: none;
  outline: none;
  outline-offset: none;
}

/* Colors */
$text-accent-color: #2e2e2e;
$text-secondary: #8d8d8d;
$purple: #673de6;
$orange: #ff620a;
$white: #fff;

/* Mixins */
@mixin transition {
  transition: all 0.3s ease;
}

/* button */
.btn {
  -webkit-appearance: initial;
  overflow: hidden;
  position: -webkit-sticky;
  position: sticky;
  z-index: 2;
  display: inline-block;
  font-size: 12px;
  border: 2px solid transparent;
  letter-spacing: 0.3px;
  line-height: inherit;
  border-radius: 0;
  text-transform: capitalize;
  width: auto;
  font-family: $poppins, sans-serif;
  font-weight: 500;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease !important;
  transition: all 0.5s ease !important;
}

/* button size */
.btn.btn-small {
  font-size: 12px;
  padding: 4px 1.8em;
}
.btn.btn-medium {
  font-size: 14px;
  padding: 12px 36px;
  line-height: 1.8em;
}
.btn.btn-large {
  font-size: 16px;
  padding: 8px 35px;
  line-height: 1.8em;
}
.btn.btn-rounded {
  border-radius: 50px;
}

/* Purple button */
.btn.btn-purple {
  background: $purple;
  border-color: $purple;
  color: #ffffff;
}
.btn.btn-purple:hover, .btn.btn-purple.active {
  background: transparent !important;
  border-color: $purple;
  color: $purple !important;
}

/* Red button */
.btn.btn-red {
  background: red;
  border-color: red;
  color: #ffffff;
}
.btn.btn-red:hover, .btn.btn-red.active {
  background: transparent !important;
  border-color: red;
  color: red !important;
}

/* Blue button */
.btn.btn-blue {
  background: transparent;
  border-color: #00b6ff;
  color: #00b6ff;
}
.btn.btn-blue:hover {
  background: #00b6ff !important;
  border-color: #00b6ff;
  color: #ffffff !important;
}

.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}

.clr-primary {
  color: $purple!important;
}

/* ===================================
    Scrollbar
====================================== */

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  width: 8px;
  background: white;
  border-left: 0 solid white;
  border-right: 0 solid white;
}

::-webkit-scrollbar-thumb {
  background: $purple;
  width: 0;
  height: 25%;
  transition: 0.5s ease;
  cursor: pointer;
}

/* ===================================
    SignIn Form
====================================== */

.signIn-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5rem 0 7.5rem 0;
}

.signIn-section .logo {
  margin: 0 0 120px 0;
}

.signIn-section .shadow-box {
  background: #ffffff;
  padding: 50px;
  border-radius: 25px;
  border: 1px solid #bfbfbf;
  box-shadow: 0px 4px 34px #0000001f;
  min-width: 580px;

  & h2 {
    font-weight: bold;
    font-size: 28px;
    color: #313640;
    text-align: center;
    margin-bottom: 40px;
  }

  & .form-group {
    margin-bottom: 20px;
  }

  & .form-group label {
    font-size: 14px;
    font-weight: 500;
    color: $text-accent-color;
  }

  & .form-group .form-field {
    position: relative;
  }

  & .form-group .form-field input {
    border: none;
    width: 100%;
    font-weight: 500;
    padding: 8px 0 8px 30px;
    border-bottom: 1px solid $text-accent-color;

    &:focus {
      border-bottom: 1px solid $purple;
    }
  }

  & .form-group .form-field img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  & .form-group .form-field.password-field input {
    padding-right: 25px;
  }

  & .form-group .form-field.password-field img {
    bottom: 35%;
  }

  & .form-group .form-field.password-field .eyeIcon {
    position: absolute;
    top: 50%;
    right: 2%;
    font-size: 20px;
    transform: translateY(-50%);
    cursor: pointer;
  }

  & form .password-forgot {
    text-align: right;

    & a {
      font-weight: 500;
      text-decoration: underline;
      @include transition;

      &:hover {
        color: $purple;
      }
    }
  }

  & .btn.btn-purple {
    border-radius: 10px;
    margin: 15px 0 20px 0;
    box-shadow: 0px 4px 8px #00000040;
  }

  & .account-signUp {
    text-align: center;

    & p {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 0;
      color: $text-accent-color;
    }
    & a {
      font-weight: bold;
      color: $orange;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.showHideIcon {
  position: absolute;
  top: 50%;
  right: 2%;
  font-size: 20px;
  transform: translateY(-50%);
  cursor: pointer;
}

.signIn-section .shadow-box input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #8c8c8c !important;
  font-weight: normal;
  font-size: 14px;
}

.signIn-section .shadow-box input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #8c8c8c !important;
  font-weight: normal;
  font-size: 14px;
}

.signIn-section .shadow-box input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #8c8c8c !important;
  font-weight: normal;
  font-size: 14px;
}

/* ===================================
    Forgot Password Form
====================================== */

.forgot-password .shadow-box .btn.btn-purple {
  margin: 0 0 40px 0;
}

/* ===================================
    Reset Password Form
====================================== */

.reset-password .shadow-box {
  & h2 {
    margin-bottom: 0;
  }

  & p.main-para {
    margin: 12px 0 30px 0;
    text-align: center;
    font-weight: normal;
    color: $text-secondary;
  }
}

.reset-password .shadow-box .btn.btn-purple {
  margin: 0;
}

/* ===================================
    Sign Up Form
====================================== */

.signUp-section {
  // background: #ede8ff;

  & .custom-container {
    width: 95%;
    margin-left: auto;
    position: relative;
  }

  & .signUp-area {
    padding: 50px 80px 35px 80px;
    background: #ffffff;
    min-height: 100vh;
    // border-radius: 65px 0 0 65px;
    margin: 50px 0px;
    border-radius: 25px;
    border: 1px solid #bfbfbf;
    box-shadow: 0px 4px 34px #0000001f;

    & h2 {
      color: #313640;
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 70px;
    }

    & .paymentInfo .fields-wrapper .form-group {
      width: 30%;
    }
  }
}

.form-tabs ul {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  cursor: pointer;

  & .liner {
    height: 2px;
    background: #ddd;
    position: absolute;
    width: 90%;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 20%;
    z-index: 1;
  }

  & li {
    font-size: 13px;
    color: #828282;
    font-weight: normal;

    &.active {
      color: $purple;
      font-weight: bold;

      & span {
        background: $purple;
      }
    }
  }

  & li span {
    display: block;
    color: #ffffff;
    margin: auto;
    height: 28px;
    width: 28px;
    z-index: 2;
    background: #6a7580;
    font-weight: normal;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
  }
}

.purple-signUp_checks {
  padding: 60px 50px;
  background: $purple;
  border-radius: 30px 0px 0px 30px;

  & .checkbox-area {
    display: flex;
    margin-bottom: 25px;

    & .heading-area h4 {
      font-size: 20px;
      color: #ffffff;
      font-weight: bold;
    }

    & .heading-area p {
      font-size: 15px;
      color: #ffffff;
      font-weight: 300;
      line-height: 24px;
      margin-bottom: 0;
    }
  }

  & .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  & .form-group label {
    position: relative;
    cursor: pointer;
  }

  & .form-group label:before {
    content: "";
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid $orange;
    background: $orange;
    padding: 10px;
    border-radius: 7px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 25px;
  }

  & .form-group input:checked + label:after {
    content: "";
    display: block;
    position: absolute;
    top: 2px;
    left: 8px;
    width: 8px;
    height: 16px;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

// Input Fields Styling
.input-fields-area {
  margin-top: 40px;

  & .form-group {
    margin-bottom: 20px;
  }

  & .form-group label {
    font-size: 14px;
    font-weight: 500;
    color: #6c6c6c;
  }

  & .form-group .form-field {
    position: relative;

    & img, & svg {
      position: absolute;
      top: 50%;
      fill: #2e2e2e;
      transform: translateY(-50%);
    }
  }

  & .form-group .form-field input,
  & .form-group .form-field select {
    border: none;
    width: 100%;
    font-weight: 500;
    background: #ffffff;
    padding: 8px 0 8px 30px;
    border-bottom: 1px solid $text-accent-color;

    &:focus {
      border-bottom: 1px solid $purple;
      outline: none;
    }
  }
}

.input-fields-area .fields-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & .form-group {
    width: 45%;
  }
  & .upload_resume {
    margin-bottom: 0px;
    
  & img {
    cursor: pointer;
    width: 30px;
    }
  }
}

.my-account .input-fields-area .fields-wrapper .form-group {
  text-align: initial;
}

.input-fields-area .terms-agreement {
  display: flex;
  align-items: center;

  & span {
    margin-left: 10px;
    font-size: 13px;
    font-weight: normal;
    color: $text-accent-color;

    &.termsMsg {
      color: red;
      font-weight: 500;
    }
  }
}

.input-fields-area .btn.btn-purple,
.input-fields-area .btn.btn-red {
  border-radius: 10px;
  padding: 10px 42px;
  min-width: 150px;
}

.input-fields-area .account-signUp {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 120px;

  & p {
    font-size: 14px;
    font-weight: 500;
    margin: 0 5px 0 0;
    color: $text-accent-color;
  }

  & a {
    font-weight: bold;
    color: $orange;
    text-decoration: underline;
    cursor: pointer;
  }
}

// Company Information
.input-fields-area .upload-logo {
  text-align: center;

  & .logo-box {
    padding: 20px;
    border-radius: 3px;
    background: #d8d8d8;
    display: inline-block;
    box-shadow: 0px 6px 20px #00000080;
    font-size: 12px;
    color: #9c9c9c;
    font-weight: normal;
    cursor: pointer;
    width: 70%;

    & .upload-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    & img {
      height: 60px;
      width: 60px;
      margin-bottom: 10px;
      object-fit: cover;
    }
  }

  & p {
    color: #6c6c6c;
    font-weight: 500;
    margin: 20px 0;
  }
}

.input-fields-area.company-info {
  margin: 120px 0;
}

.driver-detail {
  & .detail-field-row {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    // flex-wrap: wrap;

    & form span {
      margin-bottom: 5px;
    }

    & h4 {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 5px;
      width: 30%;
    }

    & select.exp-dropdown {
      margin-left: 18px;
      border-radius: 5px;
      font-size: 16px;
      color: #acacac;
      font-weight: normal;
      width: 150px;
      padding: 2px 0;
      border: 1px solid rgba(141, 141, 141, 0.6);
    }

    & .detail-info {
      width: 70%;
      display: flex;
      flex-wrap: wrap;
    }

    & span {
      padding: 0 8px;
      line-height: 1.2;
    }

    & label {
      cursor: pointer;
      display: flex;
      align-items: center;
      line-height: inherit;
      margin-bottom: 0.4rem;

      & h6 {
        font-size: 16px;
        font-weight: normal;
        color: $text-secondary;
        margin-bottom: 0;
      }

      & input {
        margin-right: 5px;
        width: 18px;
        height: 18px;
      }
    }
  }
}

.errorMsg {
  color: red;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 24px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    font-size: 16px;
    font-weight: normal;
    color: #8d8d8d;
    margin-bottom: 0;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, .5);
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: $purple;
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
[type="radio"]:checked + label {
  color: #000000 !important;
}


.checkbox-custom, .radio-custom {
  opacity: 0;
  position: absolute;   
}

.checkbox-custom, .checkbox-custom-label, .radio-custom, .radio-custom-label {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  margin-bottom: 0.5rem;
}

.checkbox-custom-label, .radio-custom-label {
  position: relative;
  font-size: 16px;
  font-weight: normal;
  color: #8d8d8d;
}

.radio-custom-label {
  width: 20px;
  height: 20px;
}

.checkbox-custom + .checkbox-custom-label:before, .radio-custom + .radio-custom-label:before {
  content: "";
  background: #fff;
  border: 1px solid rgba(0,0,0, .5);
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  width: 18px;
  height: 18px;
}

.checkbox-custom:checked + .checkbox-custom-label:before {
  content: "\f00c";
  color: $purple;
  font-size: 15px;
  text-align: center;
  font-family: 'FontAwesome';
}

.radio-custom + .radio-custom-label:before {
  border-radius: 50%;
}

.radio-custom:checked + .radio-custom-label:before {
  content: "\f00c";
  font-family: 'FontAwesome';
  color: #bbb;
}

.employ-history .form-group input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #202020 !important;
  font-weight: 500;
  font-size: 14px;
}
.employ-history .form-group input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #202020 !important;
  font-weight: 500;
  font-size: 14px;
}
.employ-history .form-group input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #202020 !important;
  font-weight: 500;
  font-size: 14px;
}

.employ-history.input-fields-area .form-group {
  margin-bottom: 25px;
}

.paymentInfoStep.input-fields-area .fields-wrapper .form-group {
  width: 30%;
}

/* ===================================
    Navbar
====================================== */

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 60px;
  height: 70px;
}

.navbar .filter-btn {
  margin-left: 20px;
  cursor: pointer;
}

.navbar .logo-search-area {
  display: flex;
  align-items: center;

  & .logo img {
    width: 70%;
  }

  & .searchBar {
    position: relative;

    & .searchIcon {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      font-size: 18px;
      color: rgba(141, 141, 141, 0.6);
    }

    & input {
      border: 1px solid rgba(141, 141, 141, 0.6);
      border-radius: 50px;
      padding: 4px 5px 4px 45px;
      font-size: 16px;
      width: 220px;
    }
  }

  .fiilterDropdown {
    margin: 0;
    padding: 0;
    width: 100%;
    z-index: 9999;
    border-radius: 4px;
    position: absolute;
    top: 40px;
    color: $white;
    overflow: hidden;
    background: #b3b3b3;
  
    & li {
      font-weight: 500;
      font-size: 16px;
      list-style: none;
      padding: 10px 15px;
      cursor: pointer;
      border-bottom: 2px solid $white;
      @include transition;
    }
  }
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #8c8c8c !important;
  font-weight: normal;
  font-size: 15px;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #8c8c8c !important;
  font-weight: normal;
  font-size: 15px;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #8c8c8c !important;
  font-weight: normal;
  font-size: 15px;
}

.navbar .nav-item {
  display: flex;
  margin-bottom: 0;

  & .nav-link {
    font-size: 16px;
    font-weight: normal;
    color: $text-secondary;
    line-height: 0.6;
    cursor: pointer;

    &.active {
      color: $purple;
      font-weight: 500;

      &:after {
        content: "";
        height: 3px;
        width: 100%;
        display: inline-block;
        background-color: $purple;
      }
    }
  }
}

.navbar .badge-profile-side .badge-icons {
  display: flex;
  margin-bottom: 0;

  & li {
    margin: 0 10px;
    font-size: 20px;
    cursor: pointer;
    position: relative;
    & span {
      width: 15px;
      height: 15px;
      font-size: 10px;
      display: flex;
      color: #ffffff;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      background: $orange;
      position: absolute;
      top: -4px;
      left: 14px;
    }

    & .msg-notify {
      top: -4px;
      left: 18px;
    }
  }
}

.navbar .badge-profile-side {
  display: flex;
  align-items: center;
}

.navbar .badge-profile-side .user-profile-img {
  margin-left: 25px;
  position: relative;
  cursor: pointer;

  & img {
    border-radius: 50px;
    width: 40px;
  }
}

.navbar .badge-profile-side .user-profile-img img.navProfileImg {
  height: 40px;
  object-fit: cover;
}

// Filter Megamenu
.mega-menu {
  background: #ffffff;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  left: 50%;
  top: 100px;
  transform: translateX(-50%);
  z-index: 9999;
  width: 1000px;
  border-radius: 10px;
  transition: visibility 0s, opacity 0.3s linear;
  padding: 40px 30px;
  max-height: 70vh;
  overflow-y: auto;
}

.logo-search-area .filter-btn li.dropdown {
  position: static;
}

.logo-search-area .filter-btn .filter-hamburger {
  font-size: 16px;
  font-weight: normal;
  text-decoration: none;
  padding: 8px 10px;
  background: $purple;
  border-radius: 6px;
  border: 1px solid $purple;

  & img {
    width: 30px;
    @include transition;
  }
  & span {
    color: $white;
    margin-left: 10px;
  }
  &:hover img {
    transform: rotateY(180deg);
  }
}

.mega-menu.active {
  visibility: visible;
  opacity: 1;
}

.navbar .filter-btn .driver-detail .detail-field-row form {
  width: 75%;
}

.navbar .filter-btn .input-fields-area {
  margin-top: 0;
}

.close-menu-btn {
  position: absolute;
  top: 3%;
  right: 2.5%;
  font-size: 20px;
}

#close_side_menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #202020;
  -webkit-transition: opacity 300ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
  -o-transition: opacity 300ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: opacity 300ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
  z-index: 1031;
  opacity: 0.5;
  display: block;
  cursor: default;
}

.navbar .filter-btn [type="radio"]:checked + label:before,
.navbar .filter-btn [type="radio"]:not(:checked) + label:before {
  border: 1px solid #8d8d8d;
}

// User Icon DropDown
.user-profile-img .mega-menu {
  background: #ffffff;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  left: 50%;
  top: 60px;
  transform: translateX(-50%);
  z-index: 9999;
  border-radius: 6px;
  transition: visibility 0s, opacity 0.3s linear;
  box-shadow: 0px 4px 20px #00000029;
  border: 1px solid #bfbfbf;
  width: 280px;
  margin-left: -100px;
}

.user-profile-img .mega-menu:after {
  content: " ";
  position: absolute;
  z-index: 9999;
  right: 9%;
  top: -15px;
  border-top: none;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 15px solid rgba(191, 191, 191, 0.5);
}

.user-profile-img .dropdown.active .mega-menu {
  visibility: visible;
  opacity: 1;
}

.user-profile-img .dropdown .mega-menu .account-info {
  list-style: none;
  padding: 10px 0;
}

.user-profile-img .dropdown .mega-menu .account-info li {
  font-weight: 500;
  font-size: 16px;
  padding: 8px 0;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;

  &:hover {
    background: rgba(191, 191, 191, 0.2);

    & a {
      color: #202020;
    }
  }

  & .chevron--right-arrow {
    position: absolute;
    top: 50%;
    right: 8%;
    font-size: 16px;
    transform: translateY(-50%);
  }
}

.user-profile-img .dropdown .mega-menu .account-info li a {
  padding: 0 15px;
  display: block;

  & img {
    width: 34px;
    height: 20px;
    margin-right: 10px;
  }
}

/* ===================================
    Home Page
====================================== */

.home-page {
  padding: 80px 0;

  & .hired-drivers {
    padding: 20px;
    margin: 40px 0;
    border-radius: 10px;
    background: rgba(191, 191, 191, 0.4);
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    text-decoration: none;
    color: $purple;
    @include transition;

    &:hover {
      background: $purple;
      color: #ffffff;
    }

    & p {
      margin-bottom: 0;
      font-weight: 500;
      font-size: 16px;
    }
  }
}

.side-card {
  box-shadow: 0px 4px 35px #00000029;
  border-radius: 25px;
  text-align: center;
  min-height: 500px;
  overflow: hidden;

  & h5 {
    font-size: 20px;
    color: #202020;
    font-weight: 500;
    margin: 20px 0 10px 0;
  }

  & p {
    color: #8d8d8d;
    font-size: 16px;
    margin-bottom: 3px;
  }

  & .btn-purple {
    margin-top: 75px;
    border-radius: 10px;
    padding: 8px 42px;
  }
}

.side-card .card-bg-img {
  position: relative;

  & img {
    height: 120px;
    width: 100%;
    object-fit: cover;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    background: rgba(0, 0, 0, .3);
    width: 100%;
    height: 100%;
    top: 0;
  }

  & .bg-editIcon {
    z-index: 98;
    height: 30px;
    width: 30px;
    color: $purple;
    font-size: 18px;
    cursor: pointer;
    border-radius: 50%;
    background: $white;
    position: absolute;
    right: 8px;
    top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.side-card .profile-img {
  margin-top: -40px;
  position: relative;

  & img {
    border-radius: 50px;
    border: 2px solid #ffffff;
    width: 80px;
    height: 80px;
    object-fit: cover;
  }
}

.right-post-side {
  margin: 0 60px;
}

.start-broadcast {
  display: flex;
  align-items: center;

  & .broradcast-field {
    width: 92%;
    position: relative;
    cursor: pointer;

    & img.camera-icon {
      position: absolute;
      top: 50%;
      right: 15px;
      font-size: 16px;
      transform: translateY(-50%);
    }
  }

  & input {
    width: 100%;
    border-radius: 50px;
    padding: 5px 42px 5px 15px;
    border: 1px solid rgba(141, 141, 141, 0.6);
  }
}

.user-avatar {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50px;
  margin-right: 15px;
}

.right-post-side .job-desc {
  display: flex;

  & .job-apply {
    
    & h5 {
      font-size: 18px;
      font-weight: 500;
      margin: 20px 0 15px 0;
      color: $text-accent-color;
    }

    & p {
      font-size: 15px;
      font-weight: normal;
    }
    & span{
      color: white;
      background: $purple;
      border-radius: 5px;
      padding: 2px 5px;
      cursor: pointer;
    }
    & img {
      margin-top: 5px;
      width: 100%;
      height: 450px;
      border-radius: 6px;
      object-fit: cover;
    }

    & button {
      margin-top: 20px;
      border-radius: 10px;
      padding: 10px 28px;
    }
  }
}

.user--name {
  font-size: 20px;
  color: #202020;
  font-weight: 500;
  margin-bottom: 0;
}

.user--country {
  color: #8d8d8d;
  font-size: 16px;
  margin-bottom: 8px;
}

.filter-posts {
  & h5 {
    font-size: 24px;
    font-weight: 600;
    color: #202020;
  }

  & .search-card {
    box-shadow: 0px 4px 35px #00000029;
    border: 0.5px solid #bfbfbf;
    border-radius: 25px;
    text-align: center;
    padding: 30px 25px;

    & .user-avatar {
      width: 50px;
      height: 50px;
      margin: auto;
      object-fit: cover;
    }

    & h5 {
      margin: 15px 0 10px 0;
      font-size: 18px;
      font-weight: 500;
    }

    & p {
      font-size: 15px;
      color: $text-secondary;
      margin: 0;
    }

    & .btn.btn-purple {
      border-radius: 10px;
      padding: 5px 12px;
      width: 100%;
    }
  }
}

.detail-wrapper {
  display: flex;
  height: 220px;
  flex-direction: column;
  justify-content: space-between;
}

/* ===================================
    Earnings Page
====================================== */

.earnings-page {
  padding: 40px 0;

  & .earn-container {
    width: 60%;
    margin: auto;

    & .top-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 20px;

      & h4 {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 0;
      }

      & select.exp-dropdown {
        margin-left: 18px;
        border-radius: 5px;
        font-size: 16px;
        color: #acacac;
        font-weight: normal;
        width: 150px;
        padding: 2px 5px;
        border: 1px solid rgba(141, 141, 141, 0.6);
      }
    }

    & .top-header.total-pricing h4 {
      font-weight: 500;
    }

    & .earn-table {
      box-shadow: 0px 4px 35px #00000029;
      border-radius: 15px;
      overflow: hidden;
      text-align: center;
      margin: 20px 0 30px 0;

      & thead {
        background: rgba(191, 191, 191, 0.2);
        color: $purple;
        font-size: 18px;
        border-bottom: 2px solid #ffffff;

        & th {
          font-weight: 500;
          padding: 1.8rem 1rem;
        }
      }

      & tbody td {
        padding: 1.5rem 1rem;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}

/* ===================================
    Acc Settings Page
====================================== */

.my-account {
  padding: 40px 0;

  & .account_tabs {
    box-shadow: 0px 4px 35px #00000029;
    border-radius: 25px;
    min-height: 445px;
    text-align: center;
    overflow: hidden;
    margin-top: 20px;

    & ul li a {
      font-size: 18px;
      font-weight: 500;
      display: block;
      padding: 15px 10px;
      transition: all 0.3s ease;
      text-decoration: none;
    }

    & ul li.active a {
      background-color: $purple;
      color: #ffffff;
    }
  }

  & h2 {
    font-weight: 600;
    font-size: 24px;
    display: block;
  }

  & .right-tabs {
    box-shadow: 0px 4px 35px #00000029;
    border-radius: 25px;
    min-height: 445px;
    margin-top: 20px;
    padding: 30px 80px;
    text-align: center;
    margin-left: 50px;

    & .input-fields-area {
      margin-top: 15px;

      & .btn.btn-purple {
        border-radius: 10px;
        padding: 10px 42px;
        min-width: 140px;
      }

      & .form-group {
        margin-bottom: 8px;

        & .form-field input {
          padding: 8px 0 4px 0;
        }
      }
    }
  }
}

.right-tabs.reset-password {
  padding: 100px 80px;

  & .input-fields-area .fields-wrapper {
    justify-content: center;
  }
}

.right-tabs.company-tab {
  & .input-fields-area .fields-wrapper {
    justify-content: center;
  }

  & .input-fields-area {
    margin-top: 25px;
  }
}

.right-tabs.driving-tab {
  padding: 30px 30px;
  text-align: initial;

  & .driver-detail .detail-field-row h4 {
    width: 30%;
  }

  & .driver-detail .detail-field-row form {
    width: 70%;
  }
}

.profilepic {
  position: relative;
  display: inline-block;

  & .avatar {
    display: block;
    width: 100px;
    height: 100px;
    object-fit: cover;
  }

  & .fileuplaod {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  & i {
    font-size: 18px;
    width: 30px;
    height: 30px;
    background: #eedede;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    cursor: pointer;
  }
}

#file-upload,#avatar-upload {
  display: none;
}

/* ===================================
    Payment Methods Page
====================================== */

.payment-methods {
  padding: 60px 0;

  & .top-area {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & h2 {
      margin-bottom: 0;
      font-size: 26px;
      font-weight: 600;
    }

    & .btn.btn-purple {
      border-radius: 10px;
      padding: 10px 36px;
    }
  }

  & .payment-wrapper {
    box-shadow: 0px 4px 35px #00000029;
    border-radius: 10px;
    margin: 20px 0;

    & .remove-card {
      text-align: center;
      padding: 12px 20px;
      border-top: 1px solid rgba(141, 141, 141, 0.5);

      & span {
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        cursor: pointer;
      }

      & i {
        font-size: 20px;
        margin-right: 12px;
      }
    }
  }

  & .payment-box {
    padding: 50px 60px;
    box-shadow: 0px 4px 35px #00000029;
    border-radius: 10px;
    margin-top: 30px;

    & .payment-card {
      padding: 30px 25px 20px 25px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      & .pcard-info {
        & h4 {
          font-size: 22px;
          font-weight: 500;
          margin-bottom: 8px;
        }

        & p {
          font-size: 16px;
          font-weight: normal;
          margin-bottom: 3px;
          color: $text-secondary;
        }
      }

      & .make-defaultCard {
        & p {
          font-size: 18px;
          font-weight: 500;
          margin-bottom: 8px;
          color: $text-secondary;
        }

        & input[type="checkbox"] {
          position: relative;
          width: 40px;
          height: 20px;
          -webkit-appearance: none;
          background: rgba(120, 120, 128, 0.16);
          outline: none;
          cursor: pointer;
          border-radius: 20px;
          transition: background 300ms linear;

          // circle
          &::before {
            position: absolute;
            content: "";
            width: 16px;
            height: 16px;
            top: 2px;
            left: 2px;
            border-radius: 20px;
            background-color: #fff;
            transition: left 300ms;
          }
          // move to right
          &:checked {
            background: $purple;
            &::before {
              left: 22px;
              background-color: #fff;
            }
          }
        }
      }
    }
  }
}

/* ===================================
    Bank Details Page
====================================== */

.bank-details {
  padding: 60px 0;
  text-align: center;

  & h2 {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 30px;
  }

  & .bd-box {
    box-shadow: 0px 4px 35px #00000029;
    border-radius: 25px;
    margin-top: 20px;
    padding: 60px 0;
    margin: 0 auto;
    width: 60%;
  }

  & .input-fields-area {
    margin-top: 0;

    & .fields-wrapper {
      // justify-content: center;
    }

    & .form-group {
      margin-bottom: 30px;
      text-align: left;
      padding: 0 30px;

      & .form-field input {
        padding-left: 0;
      }
    }
  }
}

/* ===================================
    ViewDriverDetails Page
====================================== */
.card.user-card-full {
  border: none;
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 20px 0 rgba(69 ,90 ,100, .5);
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, .5);

  & .user-profile {
    border-radius: 5px 0 0 5px;
    padding: 20px 0;
    background: $purple;
    text-transform: capitalize;

    & svg {
      color: $white;
      font-size: 24px;
      cursor: pointer;
      position: absolute;
      top: 35px;
      left: 20px;
    }
  }

  & .profile-side {
    color: $white;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  & .card-block {
    padding: 2rem 1rem;

    & .driver-avatar img {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      object-fit: cover;
    }
    & h6 {
      margin-bottom: 0;
    }
    & p {
      line-height: 25px;
      margin: .4rem 0 1rem 0;
    }
    & .b-b-default {
      border-bottom: 1px solid #e0e0e0;
    }
    & .emp_history .col-sm-6:last-child p {
      margin-bottom: 0;
    }
    & button {
      border-radius: 8px;
    }
  }
}

/* ===================================
    Message SideMenu
====================================== */

.sidebar {
  width: 40%;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  background: #ffffff;
  z-index: 1032;
  height: 100%;
  max-height: 100vh;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  -o-transition: -o-transform 0.5s ease;
  -o-transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  overflow-x: hidden;
}

.sidebar.active {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.sidebar .bar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 1px 4px #00000029;
  padding: 25px 30px;
  margin-bottom: 5px;

  & .main-heading {
    display: flex;
    align-items: center;

    & h4 {
      margin-bottom: 0;
      font-size: 24px;
      font-weight: 500;
    }

    & .msg-counter {
      background: #ff620a;
      color: #ffffff;
      width: 22px;
      height: 22px;
      border-radius: 50px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 15px;
    }
  }

  & .close-sidebarMenu {
    font-size: 18px;
    cursor: pointer;
  }
}

.user-message-box {
  padding: 15px 30px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(141, 141, 141, 0.5);
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    background: rgba(191, 191, 191, 0.5);
  }

  & img {
    width: 60px;
    height: 60px;
    border-radius: 50px;
    margin-right: 15px;
    object-fit: cover;
  }

  & p {
    margin: 5px 0 0;
    line-height: 1.4;
    color: $text-secondary;
  }

  & .user_name {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & h5 {
      margin-bottom: 0;
    }

    & span {
      color: #bfbfbf;
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.w-80 {
  width: 80%;
}

.bg-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  z-index: 2;
  background: #000000;
}

.bg-overlay-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  z-index: 999;
  background: #000000;
}

.chat-container {
  display: flex;
  padding: 8px 15px;

  & img {
    height: 50px;
    width: 50px;
    object-fit: cover;
    border-radius: 50px;
  }
  & .msg-outer-div {
  width: 100%;
  & .show_msg_time {
    text-align: end;
    margin-bottom: 0px;
  }
  }
  & .chat-message {
    width: 98%;
    padding: 12px 15px;
    margin: 25px 8px 0 0;
    background: #bfbfbf;
    // border-radius: 41px 0 41px 41px;
    border-radius: 14px 0 14px 14px;

    & p {
      margin-bottom: 0;
      overflow: hidden;
      font-size: 15px;
      line-height: 1.3;
      color: #000000;
      margin-bottom: 15px;
      & a{
        color: blue;
      }
    }
    & img{
      width: 130px;
      height: 100px;
      border-radius: 4px;
    }
  }

  &.msg-sender {
    flex-direction: row-reverse;

    & .chat-message {
      border-radius: 0px 14px 14px 14px;
      margin: 25px 0 0 8px;
      background: #eae9e9;
      width: 98%;
    }
  }
}

.chatting-model .bar-header {
  width: 100%;
  z-index: 9999;
  position: sticky;
  top: 0;
  left: 0;
  background: $white;
}

.chat-wrapper {
  padding: 0 0 60px;
  min-height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  padding: 80px 0;
}

.chat-input {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;

  & input.msg-field {
    width: 100%;
    height: 50px;
    outline: none;
    padding: 0 35px 0 32px;
    color: #202020;
    font-weight: 500;
    margin: 0 auto;
    background: #fafafa;
    border: 2px solid rgba(141, 141, 141, 0.6);
  }

  & .fa-paper-plane {
    width: 30px;
    position: absolute;
    font-size: 18px;
    right: 0;
    top: 50%;
    cursor: pointer;
    z-index: 99;
    transform: translateY(-50%);
    color: rgba(141, 141, 141, 0.6);
  }
  & label {
    width: 30px;
    position: absolute;
    font-size: 26px;
    left: 5px;
    top: 50%;
    cursor: pointer;
    z-index: 99;
    transform: translateY(-50%);
    color: rgba(141, 141, 141, 0.6);

    & .attach-icon {
      display: none;
    }
  }
}

.channel__body__date span {
  display: block;
    text-align: center;
}

.create-broadcast {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1032;
  width: 65vw;
  min-height: 452px;
  max-height: 95vh;
  overflow-y: auto;
  background: #ffffff;
  border-radius: 10px;
  transform: translate(-50%, -50%);

  & .broadcast-header {
    padding: 25px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid rgba(191, 191, 191, 0.5);

    & h5 {
      margin-bottom: 0;
      font-weight: 500;
      font-size: 20px;
    }

    & i {
      font-size: 16px;
      cursor: pointer;
    }
  }

  & .broadcast-body {
    padding: 25px 20px 10px 25px;

    & textarea {
      width: 100%;
      display: block;
      font-size: 16px;
      height: 150px;
      padding: 12px;
      resize: none;
      border-radius: 6px;
      border: 1px solid #2e2e2e;
    }
  }

  & .broadcast-footer {
    padding: 10px 0;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    border-top: 2px solid rgba(191, 191, 191, 0.5);

    & .btn.btn-purple {
      border-radius: 50px;
      padding: 10px 20px;
    }

    & input{
      width: 70px;
      opacity:0;
    }
  
    & .for-sm-input-file{
        width: 40px;
        cursor: pointer;
        text-align: center;
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 30;
    }
  }
}


.Jobs_Detail {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1032;
  width: 40vw;
  min-height: 270px;
  background: #ffffff;
  border-radius: 10px;
  transform: translate(-50%, -50%);

  & .broadcast-header {
    padding: 20px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid rgba(191, 191, 191, 0.5);

    & h5 {
      margin-bottom: 0;
      font-weight: 500;
      font-size: 20px;
    }
    & i {
      font-size: 16px;
      cursor: pointer;
    }
  }

  & .broadcast-body {
    padding: 25px 20px 10px 25px;
    & .broadcast-footer {
      padding: 10px 0;
      display: flex;
      align-items: center;
      position: relative;
      justify-content: space-between;
  
      & .btn.btn-purple {
        border-radius: 50px;
        padding: 10px 20px;
      }
      
    }

  }
 
}

.edit-broadcast {
  & .profilepic .avatar {
    width: 60px;
    height: 60px;
  }
  & .profilepic i {
    font-size: 15px;
    width: 24px;
    height: 24px;
  }
  & .profilepic .fileuplaod {
    margin-bottom: 0;
  }
}

/* ===================================
    Bank Detail Popup
====================================== */

.sidebar.center-popup {
  left: 50%;
  top: 50%;
  width: 40%;
  transform: translate(-50%, -50%);
  height: 300px;
  border-radius: 10px;
  overflow: hidden;

  & .bar-header {
    padding: 8px 20px;
    box-shadow: none;
  }

  & .model-info {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
    text-align: center;
    flex-direction: column;
    position: relative;

    & .close-icon {
      position: absolute;
      top: 2%;
      right: 2%;
      font-size: 30px;
      cursor: pointer;
      @include transition;

      &:hover {
        color: $purple;
      }
    }

    & button {
      margin: 0 10px;
      border-radius: 10px;
    }
  }
}

// Other Components Style
.text-purple {
  color: $purple;
}

.driver-info {
  padding: 60px 0;

  & h3 {
    text-align: center;
    margin-bottom: 30px;
  }

  & .profile-img {
    text-align: center;

    & img {
      border-radius: 50px;
      border: 2px solid #8d8d8d;
    }
  }

  & .input-fields-area {
    & .form-group {
      margin-bottom: 25px;
    }

    & .fields-wrapper .form-group:nth-child(2) {
      margin-left: 40px;
      margin-right: 40px;
    }

    & .form-group .form-field input {
      padding: 4px 0 8px 0;
    }

    & .info-btns {
      display: flex;
      justify-content: center;

      & button:nth-child(1) {
        margin-right: 30px;
      }

      & .btn.btn-purple {
        margin-top: 30px;
        border-radius: 6px;
        padding: 10px 48px;
        min-width: 120px;
        font-size: 16px;
      }
    }
  }
}

.paymentMethods-sec {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;

  & .bg-overlay {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 1);
  }
}

.input-fields-area.paymentInfoStep {
    width: 65%;
    background: #ffffff;
    padding: 40px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 12px;
    z-index: 100;

    & .closeIcon {
      font-size: 20px;
      position: absolute;
      right: 20px;
      top: 15px;
      cursor: pointer;
    }
}

.deleteFormBtn {
  color: red;
  margin: 0 15px;
  @include transition;
}

.addFormBtn {
  outline: none;
  border: none;
  background: transparent;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  @include transition;

  & span {
    margin: 0 5px;
  }

  &:hover {
    color: $purple;
  }

  &.active, &:focus {
    outline: none;
    border: none;
  }
}

.panel-group {
  margin-top: 30px;
}

.panel-group:not(:first-child) {
  margin-top: 0;
}

.panel-collapse {
  max-height: 700px;
  overflow: hidden;
  transition: max-height 1s ease;
}

.panel-close {
  max-height: 0;
  transition: max-height .5s ease;
}

.panel-heading {
  & h4 {
    cursor: pointer;
    margin-bottom: 30px;
    position: relative;
    @include transition;

    &:hover {
      color: $purple;
    }

    &:after {
      content: '\f077';
      height: 20px;
      width: 20px;
      position: absolute;
      font-size: 18px;
      right: 0;
      top: 50%;
      font-family: "FontAwesome";
      transform: translateY(-50%);
      @include transition;
    }

    &.active:after {
      content: '\f078';
    }
  }
}

.bg-overlay.bg-fixed {
  position: fixed;
}

.multiSelect-dropdown {
  z-index: 9999;
  width: 100%;
  font-weight: 500;
  font-family: $poppins;

  & span {
    padding: 0!important;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* UserDropdown */
.userdropdown {
  cursor: pointer;

  .three-dots {
    font-size: 20px;
    height: 40px;
    width: 40px;
    display: flex;
    color: #ffffff;
    align-items: center;
    border-radius: 40px;
    justify-content: center;
    background: $purple;
  }

  .sharelink {
    padding: 0;
    font-size: 18px;
    font-weight: 400;
    color: #6d6e7a;
    user-select: none;
    margin-bottom: 20px;
    padding-bottom: 20px;
    @include transition;
    border-bottom: 1px solid #eaeaea;
    &:last-child {
      border-bottom: 0;
      margin-bottom: 0;
      padding-bottom: 0;
    }
    &:hover {
      color: $purple;
      background: transparent;
    }
  }
  
  .dropdown-menu {
    border: 0;
    margin-top: 10px;
    min-width: 230px;
    padding: 20px 35px;
    border-radius: 15px;
    box-shadow: 0px 5px 10px rgba(107, 107, 93, 0.15);
    a {
      font-size: 18px;
      font-weight: normal;
      color: #6d6e7a;
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #eaeaea;
      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}

/*===================================
    Loader
======================================*/

.lds-roller {
  display: inline-block;
  width: 80px;
  height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.lds-roller div {
  -webkit-animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -webkit-transform-origin: 40px 40px;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  -webkit-animation-delay: -0.036s;
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  -webkit-animation-delay: -0.072s;
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  -webkit-animation-delay: -0.108s;
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  -webkit-animation-delay: -0.144s;
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  -webkit-animation-delay: -0.18s;
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  -webkit-animation-delay: -0.216s;
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  -webkit-animation-delay: -0.252s;
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  -webkit-animation-delay: -0.288s;
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

.overlay {
  background: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
}

@-webkit-keyframes lds-roller {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes lds-roller {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.role-select-popup {
  left: 50%;
  top: 50%;
  width: 42%;
  height: fit-content;
  border-radius: 10px;
  overflow: hidden;
  transform: translate(-50%, -50%);
  padding: 30px 50px;

  & .model-info {
    margin-top: 30px;
    text-align: center;

    & label {
      width: 100%;
      font-size: 16px;
      font-weight: 500;
      text-align: left;
      margin-bottom: 0;
    }
  }

  & .popup-header {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #202020;

    & h5 {
      margin-bottom: 0;
    }

    & svg {
      margin-right: 10px;
      font-size: 24px;
    }
  }
  
  & .role-tab {
    display: flex;
    align-items: center;
    border: 2px solid $purple;
    padding: 15px;
    border-radius: 12px;
    justify-content: space-between;
    margin-bottom: 30px;
    background: -webkit-gradient(linear, left top, right top, color-stop(50%, rgba(103, 61, 230, 0.90)), color-stop(50%, $white));
    background: -o-linear-gradient(left, rgba(103, 61, 230, 0.90) 50%, $white 50%);
    background: linear-gradient(to right, rgba(103, 61, 230, 0.90) 50%, $white 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .3s ease-out;

    & .radio-text {
      @include transition;
    }

    & h6 {
      margin-bottom: 0;
    }

    &:hover {
      background-position: left bottom;

      & .radio-text {
        color: #ffffff!important;
        @include transition;
      }
    }
  }

  & button.btn-purple {
    margin: 0;
    font-size: 14px;
    padding: 10px 25px;
    min-width: auto;
    border-radius: 10px;
    & svg {
      font-weight: bold;
      font-size: 18px;
    }
  }
}

.hidden-msg {
  text-align: center;
  color: $text-secondary;
}

/* ===================================
    Tribe Section
====================================== */

.tribe__side-card {
  & .card-bg-img {
    & h2 {
      font-size: 26px;
      text-align: left;
      margin-bottom: 0;
      padding: 16px 20px;
    }

    & .bg-editIcon {
      top: 12px;
      right: 10px;
      color: $purple;
      z-index: 99;
      background: transparent;
    }

    &:after {
      background: transparent;
    }
  }
}

.tribe-aside {
  position: relative;

  & img {
    display: block;
    object-fit: cover;
    overflow: hidden;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  & .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.aside__message-box {
  width: 95%;
  padding: 1rem;
  cursor: pointer;
  text-align: center;
  margin: 0 auto 15px;
  border-radius: 0.8rem;
  background: hsl(240deg, 78%, 98%);
}

.aside__quantity-msg {
  height: 24px;
  display: inline-block;
  min-width: 25px;
  color: #fff;
  border-radius: 50%;
  background: #2cd479;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aside__details {
  width: 50px;
  flex-grow: 1;
  margin-left: .8rem;

  & h3 {
    font-weight: bold;
    font-size: 16px;
    text-align: left;
    margin-bottom: 0;
  }
}

.aside__condition {
  font-size: .8rem;
  color: hsl(0, 0%, 70%);

  & .aside_condition-time {
    line-height: 1.4;
  }
  & time {
    line-height: 1.4;
  }
}

.rich-text-editor {
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid $text-secondary;

  & .editorBody {
    padding: 0 10px;
  }

  & .demo-option-custom-wide {
    display: none;
  }
}

.tribe-editor {
  & .broadcast-body {
    padding: 15px 20px 10px 20px;

    & .cast-user {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }
  }
}

.rich-text-editor .quill .ql-editor {
  height: 320px!important;

  &.ql-blank::before {
    font-size: 16px;
    font-weight: 500;
    opacity: .8;
    font-family: $poppins;
  }
  & p {
    font-size: 16px;
  }
}

.center-popup.tribe-popup {
  & .model-info {
    text-align: left;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 40px 50px 50px;

    & button {
      margin: 0;
    }

    & .upload-wrapper {
      margin-bottom: 1.6rem;
      & img {
        width: 50px;
        height: 50px;
        object-fit: cover;
        margin-right: 20px;
      }
    }
  }
  
  & form {
    width: 100%;
  }

  & .detail-field-row {
    display: flex;
    margin-bottom: 1.6rem;

    & .detail-info {
      width: 100%;
      
      & label {
        margin-bottom: 0;
        margin-right: 15px;
      }
    }

    & .multiSelect-dropdown {
      font-weight: normal;
    }

    & .multiSelect-dropdown > div {
      font-size: 16px;
      padding: 6px 10px 6px 4px;
      border: 1px solid #757575;
    }

    & h4 {
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 0;
      margin-right: 20px;
    }
  }
}

.custom-input {
  width: 100%;
  position: relative;
  margin: 1.5rem 0 1.6rem;

  & input {
    width: 100%;
    font-size: 16px;
    padding: 12px 16px 14px 16px;
    border: 1px solid #757575;
    border-radius: 4px;
    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  & input:focus {
    outline: none;
    caret-color: $purple;
    box-sizing: border-box;
    border: 1px solid $purple;
    caret-color: var(--mdc-theme-primary, $purple);
    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: inset 0 0 1px $purple, inset 0 0 1px $purple, inset 0 0 1px $purple;
  }
  & label {
    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 16px;
    top: 14px;
    transition: 0.3s ease all;
    -moz-transition: 0.3s ease all;
    -webkit-transition: 0.3s ease all;
  }
  & input:focus ~ label,
  & input:valid ~ label,
  & input:active ~ label {
    top: -16px;
    left: 14px;
    padding: 4px;
    color: $purple;
    font-size: 12px;
    margin: 0;
    background: $white;
  }
}

.tribe-right {
  & .bg-editIcon {
    height: 34px;
    width: 34px;
    min-width: 34px;
    color: $white;
    line-height: 28px;
    text-align: center;
    border-radius: 50%;
    margin-left: 12px;
    background: $purple;
    font-size: 20px;
  }
}

.profile-info{
  width: 100%;
  height: 300px;
}

.profile-image{
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin: 30px auto;
}

.profile-image img{
  display: block;
  object-fit: cover;
  overflow: hidden;
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

.heading-text{
  text-align: center;
}

.created-group{
  width: 92%;
  margin: auto;
  text-align: center;
}

.participants-num{
  display: flex;
  justify-content: space-between;
  margin: 0 20px 0 20px;

  & h5 {
    line-height: initial;
    margin-bottom: 0;
  }

  & h4{
    color: $purple;
    cursor: pointer;
  }
}

.delete-icon{
  text-align: right;
  color: $purple;
  cursor: pointer;
}

.user-message-box .user_name .delete-icons {
  color: #ff0000;
  font-size: 20px;
  cursor: pointer;
}

.mem-list {
  cursor: default;

  & img {
    height: 50px;
    width: 50px;
  }
}

.remove-bg{
  color: $purple;
  background: #fff;
}

.messages-heading {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $text-secondary;
}

// New applicant feature css

.dropdown-item {
  display: block !important;
}

.imgBx img {
  height: 40px;
  width: 40px;
}

.header {
  position: relative;
}

.header img {
  height: 200px;
  object-fit: cover;
}

.header .header-text {
  position: absolute;
  left: 0px;
  bottom:0px;
  background-color: rgba(31, 28, 28, 0.493);
} 

.tribe-header h5 {
  text-align: left;
  margin-left: 20px;
}

.description-popup .model-info {
  padding: 30px 20px 30px !important;
}

.description-dots {
  font-weight: bold;
  font-size: 20px;
}

.signup-selection {
  font-size: 16px;
  font-weight: 500;
  border: 2px solid #673de6;
  border-radius: 12px;
}

.feed-page .user-jobs-box {
  cursor: pointer;
}

.user-jobs-box {
  // padding: 15px 30px;
  padding: 25px 15px;

  display: flex;
  border-radius: 10px;
  margin-bottom: 15px;
  background: #ffffff;
  align-items: flex-start;
  transition: all 0.3s ease;
  border: 1px solid rgba(141, 141, 141, 0.5) !important;

  &:hover {
    background: rgba(191, 191, 191, 0.5);
  }

  & img {
    width: 60px;
    height: 60px;
    border-radius: 50px;
    margin-right: 15px;
    object-fit: cover;
  }

  & p {
    margin: 5px 0 0;
    line-height: 1.4;
    color: $text-secondary;
  }

  & .user_name {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & h5 {
      margin-bottom: 0;
    }

    & span {
      color: #bfbfbf;
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.user-jobs-detail {
  padding: 10px 20px;
  background: #ffffff;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;

  & p {
    margin: 5px 0 0;
    line-height: 1.4;
    color: $text-secondary;
  }

  & .user_name {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & h5 {
      margin-bottom: 10;
    }

    & span {
      color: #bfbfbf;
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.broadcast-body .form-group .form-field input {
  border: none;
  width: 100%;
  font-weight: 500;
  padding: 8px 0 8px 0px;
  border-bottom: 1px solid $text-accent-color;

  &:focus {
    border-bottom: 1px solid $purple;
  }
}

.broadcast-body .form-group .form-field select {
  border: none;
  width: 100%;
  font-weight: 500;
  padding: 8px 0 8px 0px;
  border-bottom: 1px solid $text-accent-color;

  &:focus {
    border-bottom: 1px solid $purple;
  }
}

.broadcast-body .detail-field-row label {
  font-size: 14px;
    font-weight: 500;
    color: #6c6c6c;
}

.mega-menu .input-fields-area .detail-field-row .sales-selection {
  width: 30%;
  padding: 5px 8px;
  border-radius: 5px;
  color: darkgrey;
  font-weight: 400;
  border: 1px solid lightgrey;
}

.job--details {
  & .card.user-card-full {
    box-shadow: none;

    & .card-block {
      padding: 2rem 0rem;
    }
  }

  .job--card {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;

    & h5 {
      font-size: 20px;
      color: #202020;
      font-weight: 500;
      margin-bottom: 0;
      margin-right: 20px;
    }
    & p {
      color: #8d8d8d;
      font-size: 16px;
      margin-left: 20px;
      margin-bottom: 0px;
    }
  }

  .user-jobs-box {
    margin-top: 10px;
    min-width: fit-content;
    width: 645px;
    &:hover {
      background: rgba(191, 191, 191, 0.5);
    }

    & img {
      width: 50px;
      height: 50px;
      margin-top: 0;
      border-radius: 50px;
      margin-right: 15px;
      object-fit: cover;
    }

    & p {
      margin: 5px 0 0;
      line-height: 1.4;
      color: $text-secondary;
    }

    & .user_name {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & h5 {
        margin-bottom: 0;
      }
    }
  }
}

.job--details img {
  margin-top: 5px;
  width: 100%;
  max-height: 450px;
  object-fit: cover;
  border-radius: 6px;
}

.desires-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  & p {
    margin-bottom: 0!important;
  }
}

.job--details .card.user-card-full .card-block h6 {
  margin-bottom: 10px;
}

.job--details .card.user-card-full .card-block p {
  font-size: 16px;
}

.two-col-grid {
  display: grid!important;
  grid-template-columns: repeat(2, 1fr);
}


.three-col-grid {
  display: grid!important;
  grid-template-columns: repeat(3, 1fr);
}

.four-col-grid {
  display: grid!important;
  grid-template-columns: repeat(4, 1fr);
}

.step-two .three-col-grid {
  width: 70%;
}

.contact-form.input-fields-area .upload-logo .logo-box {
  padding: 0;
  width: 100%;
  box-shadow: none;
  padding-bottom: 5px;
  background: transparent;
  border-radius: 0;
  border-bottom: 1px solid #2e2e2e;
}

.contact-form.input-fields-area .upload-logo .logo-box .upload-wrapper img {
  margin-right: 20px;
  margin-bottom: 0;
  width: 42px;
  height: 42px;
}

.contact-form.input-fields-area .upload-logo .logo-box .upload-wrapper .resume_icon {
  object-fit: contain;
  width: 32px;
  height: 32px;
  margin-right: 15px;
}

.dev-skills .detail-field-row label:first-child {
  width: 20%;
}

.b-radius-10 {
  border-radius: 10px;
}

.broadcast-field {
  resize: none;
}

.broadcast-footer .chat-input {
  top: 50%;
  left: auto;
  right: 5%;
  transform: translateY(-50%);

  & label {
    color: $purple;
  }
}

.broadcast-body .uploaded-wrapper {
  position: static;
}


.home-page .engaged-driver-style{
  margin: 0;
  padding: 15px 18px;
}

.right-post-side button {
  border-radius: 10px;
}

.detail-field-row .detail-info .field-Info {
  padding: 7px;
  border-radius: 5px;
  width: -webkit-fill-available;
  border: 1px solid lightgray;
}

.uploaded-wrapper {
  // position: absolute;
  // bottom: 60px;
  // left: 10px;
  margin-left: 15px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 0;

  & div {
    position: relative;
    // margin-bottom: 15px;
  }

  & i.close-btn {
    z-index: 99;
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    top: -15%;
    right: 0;
    transform: translateX(30%);
    // color: $purple
  }

  & img {
    height: 70px;
    width: 75px;
    border-radius: 8px;
    object-fit: cover;
    border: 1px solid lightgray;
  }

  & .uploaded-documents {
    padding: 12px 16px;
    border-radius: 8px;
    color: $white;
    background: $purple;
    display: flex;
    margin-bottom: 12px;
    align-items: center;

    & label {
      margin-left: 8px;
      font-size: 14px;
      margin-bottom: 0;
    }
    & i {
      font-size: 18px;
      padding: 4px 8px;
      background: $white;
      border-radius: 6px;
    }
  }
}

.message-images {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;

  & img {
    height: 140px;
    width: 140px;
    border-radius: 6px;
  }
}

.documents-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 15px;
  gap: 10px;

  & a {
    color: $white;
    padding: 12px;
    font-size: 15px;
    cursor: pointer;
    border-radius: 8px;
    background: $purple;

    & div {
      display: flex;
      align-items: center;
    }
  }
  & label {
    margin: 0 0 0 10px;
  }
  & i {
    font-size: 20px;
    background: $white;
    padding: 4px 6px;
    border-radius: 6px;
  }
}


.broadcast-preview {
  // display: flex;
  // width: 88%;
  justify-content: center;
  flex-direction: column;

  & p {
    margin-bottom: 15px!important;
    overflow: hidden;  
  }
  & a {
   color: blue;
  }
  & .user--country {
    width: 98%;
  }

  & .preview-image{
    height: 250px;
    width: 100%;
    border-radius: 4px;
  }

  & iframe {
    width: 100%;
    height: 250px;
    border-radius: 8px;
    border: 2px solid rgba(141, 141, 141, 0.5);
  }
  & object {
    width: 100%;
    height: 250px;
    border-radius: 8px;
    border: 2px solid rgba(141, 141, 141, 0.5);
    & img {
      width: 70% !important;
      height: 150px !important;
      object-fit: fill !important;
      border-radius: 8px !important;
      border: 2px solid rgba(141, 141, 141, 0.5);
      margin: 0%;
    }
  }
}

.driver-profile label {
  font-size: 14px;
  font-weight: 500;
  color: #6c6c6c;
}

.home-page button {
  border-radius: 10px;
}

// =============================

.news-item {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  text-align: left;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 60px;

  &:hover {
    transform: translateY(-5px);
  }
  & img {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    object-fit: cover;
  }
  & .news-text-box {
    padding: 30px;
    background-color: #fff;

    & .story-info {
      margin-left: 25px;

      & h3 {
        font-size: 24px;
        font-weight: normal;
        margin-bottom: 0;
        text-transform: capitalize;
      }
      & p {
        margin-bottom: 0;
        font-size: 14px;
      }
    }
  }
  & .news-title {
    margin: 10px 0;
    font-weight: 400;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  & .news-title:hover {
    color: #03a9f5;
  }
}

.no-story-text {
  width: 100%;
  margin: 60px 0 80px 0;
  text-align: center;

  & h2 {
    font-size: 28px;
    color: grey;
  }
}

.earning_icons{
  font-size: 22px;
  padding: 8px;
  color: gray;
}

.home-page .bd-profil-box{
  padding: 40px 0px !important;
  width: 100% !important;
}

.job--details .user-jobs-box .message-images img {
  width: 150px;
  height: 150px;
  border-radius: 4px;
}

.job--details .user-jobs-box .preview-image{
  height: 250px;
  width: 100%;
  border-radius: 4px;
}


.fields-wrapper .fin-info {
  color: gray;
  cursor: pointer;
}

.user-jobs-box .message-images img {
  width: 150px;
  height: 150px;
  border-radius: 4px;
}

.user-post-button {
  margin-left: 15px;
}
.stripe-icon {
  height: 40px;
}
.payment-submit {
  display: flex;
  justify-content: space-between;
}

.privacy-policy{
  color: blue;
}

// =============

.news-border {
  border: 1px solid rgba(141, 141, 141, 0.5) !important;
}
.sales-endorsement{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr) !important;

}
/* ===================================
    Media Queries
====================================== */

@media (max-width: 1400px) and (min-width: 1280px) {
}

@media (max-width: 1200px) and (min-width: 992px) {
  .signUp-section .signUp-area {
    padding: 100px 40px 35px 40px;
  }
  .navbar .filter-btn .mega-menu {
    width: 850px;
    margin-left: 0;
  }
  .navbar .filter-btn .mega-menu:after {
    left: 48.5%;
  }
  .sidebar {
    width: 50%;
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .signUp-section .signUp-area {
    padding: 100px 30px 35px 30px;
  }
  .purple-signUp_checks {
    padding: 60px 30px;
  }
  .navbar .logo-search-area .searchBar input {
    width: 150px;
  }
  .navbar {
    margin: 0 30px;
    height: 80px;
  }
  .navbar .badge-profile-side .user-profile-img {
    margin-left: 15px;
  }
  .right-post-side {
    margin: 0;
  }
  .driver-detail .detail-field-row h4 {
    font-size: 16px;
  }
  .driver-detail .detail-field-row form label {
    font-size: 16px;
  }
  .navbar .filter-btn .mega-menu {
    width: 700px;
    margin-left: 0;
  }
  .filter-hamburger span {
    display: none;
  }
  .navbar .filter-btn {
    margin: 0;
  }
  .navbar .filter-btn .mega-menu:after {
    left: 50%;
  }
  .earnings-page .earn-container {
    width: 90%;
  }
  .my-account .right-tabs {
    margin-left: 0;
    padding: 30px 40px;
  }
  .bank-details .bd-box {
    padding: 60px 60px;
  }
  .bank-details .bd-box .input-fields-area .fields-wrapper .form-group {
    width: 60%;
  }
  .payment-methods .payment-box {
    padding: 40px 30px;
  }
  .card.user-card-full .profile-side {
    text-align: center;
  }
  .sidebar {
    width: 60%;
  }
}

@media screen and (max-width: 767px) {
  .paymentMethods-sec .input-fields-area form .payment-submit {
    display: block !important; 
    text-align: center;
    & button {
      width: 100%;
      margin-bottom: 15px;
    }
  }
  .paymentMethods-sec .input-fields-area form .payment-submit button {
    width: -webkit-fill-available;
    margin-top: 10px;
  }
  .job--details .user-jobs-box {
    width: auto;
  }
  .job--details .user-jobs-box p {
    width: 245px;
  }
  .job--details {
    & .card.user-card-full {
      // box-shadow: none;
  
      & .card-block {
        padding: 1rem 0rem;
      }
    }
  }

  .signIn-section .shadow-box {
    width: 95%;
    min-width: 95%;
    padding: 40px 20px;
  }
  .signIn-section .logo {
    margin: 0 0 80px 0;
  }
  .signUp-section .custom-container {
    width: 100%;
  }
  .purple-signUp_checks {
    padding: 40px 30px;
    margin: 60px 0 30px 0;
    display: none;
  }
  .signUp-section .signUp-area {
    padding: 60px 30px 35px 30px;
    width: 95%;
    margin: auto;
    & .input-fields-area form .info-buttons {
      width: 100%;
      display: flex;
    }
    & .input-fields-area .detail-field-row {
      display: block !important;
      .detail-info {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr) !important;
      }
    }
    & .input-fields-area .fields-wrapper {
      display: block;
    }
    & .form-group {
      width: 100% !important ;
    }
    & .input-fields-area .driver-detail .detail-field-row {
      display: block !important;
      & .detail-info {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr) !important;
      }
      & label {
        width: 100%;
      }
      
      & .sales-endorsement {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr) !important;
      }
    }
    
  }
  .form-tabs ul .liner {
    width: 82%;
  }
  .navbar {
    margin: 0;
    height: 100px;
    justify-content: space-evenly;
  }
  .navbar .nav-item .nav-link {
    font-size: 15px;
  }
  .navbar .logo-search-area .logo {
   display: flex;
   justify-content: center;
  }
  .navbar .logo-search-area .logo img {
    width: 70%;
  }
  .navbar .logo-search-area .searchBar input {
    width: 175px;
  }
  .navbar .badge-profile-side .user-profile-img {
    margin-left: 10px;
  }
  .navbar .badge-profile-side {
    margin-top: 12px;
    position: relative;
    z-index: 99;
  }
  .side-card {
    box-shadow: none;
    border: 1px solid #8d8d8d;
    min-height: 400px;
    margin-bottom: 50px;
  }
  .side-card .btn-purple {
    margin-top: 50px;
  }
  .right-post-side {
    margin: 0;
  }
  .filter-posts .search-card {
    margin: 0;
    padding: 30px 10px;
    border-radius: 14px;
  }
  .filter-posts .search-heading {
    text-align: center;
    margin-bottom: 30px;
  }
  .driver-detail .detail-field-row h4 {
    width: 100%;
    font-size: 16px;
    margin-bottom: 10px;
  }
  .driver-detail .detail-field-row select.exp-dropdown {
    width: 100%;
    margin: 0;
  }
  .driver-detail .detail-field-row form label {
    font-size: 16px;
  }
  .signup-logo {
    margin: 60px 20px;
  }
  .mega-menu {
    width: 95%;
    padding: 15px;
  }
  .user-profile-img .mega-menu {
    transform: translateX(-25%);
    & .account-info {
      z-index: 1000;
    }
  }

  .mega-menu .input-fields-area .detail-field-row  {

    display: block;
    & .sales-selection {
      width: 100%;
    }
    & .detail-info{
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
   
    & .states {
      width: 100%;
      display: block;
    }

    & span {
      padding: 0px;
    }
  }

  .close-menu-btn {
    top: 1%;
    right: 5%;
}
  .driver-detail .detail-field-row .detail-info {
    width: 100%;
  }
  .mega-menu:after {
    left: 50%;
  }
  .navbar .filter-btn .driver-detail .detail-field-row {
    flex-wrap: wrap;
  }
  .navbar .filter-btn .driver-detail .detail-field-row h4 {
    width: 100%;
    font-size: 15px;
    margin-bottom: 15px;
  }
  .navbar .filter-btn .driver-detail .detail-field-row {
    width: 100%;
    & select.exp-dropdown {
      margin-left: 0;
    }
    & form {
      & label {
        font-size: 15px;
      }
      & span  {
        padding-left: 0;
      }
    }
    
  }
  
  .earnings-page .earn-container {
    width: 90%;
  }
  .my-account .right-tabs {
    margin-left: 0;
    padding: 30px 30px;
    & .input-fields-area
    {
      .fields-wrapper {
        display: block;
      }
      .detail-field-row {
        display: block;
        & h4 {
          width: 100%;
        }
        & .detail-info {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
        }
      }
    } 
    
  }
  .my-account .account_tabs {
    border-radius: 15px;
    margin: 30px auto;
    min-height: auto;
  }
  .bank-details .bd-box {
    padding: 40px 0px;
    width: 95%;
  }
  .bank-details .input-fields-area .fields-wrapper {
    display: initial; 
  }
  .bank-details .input-fields-area .b-detail-submit{
    display: block !important; 
    text-align: center;
    & button {
      width: 100%;
      margin-bottom: 15px;
    }
  }
  .bank-details .bd-box .input-fields-area .fields-wrapper .form-group {
    width: 100%;
    display: block;
  }
  .payment-methods .payment-box {
    padding: 30px 15px;
  }
  .payment-methods .payment-wrapper {
    margin-bottom: 40px;
  }
  .payment-methods .top-area h2 {
    font-size: 20px;
  }
  .payment-methods .top-area .btn.btn-purple {
    border-radius: 10px;
    padding: 10px 30px;
  }
  .sidebar {
    width: 100%;
   & .user-message-box {
    padding: 15px 10px;
   }
   & .user-message-box img {
    width: 45px;
    height: 45px;
    }
   & .user-message-box .user_name h5{
      font-size: 1rem;
    }
    & .user-message-box .user_name span{
      font-size: 1rem;
    }
    & .user-message-box p {
      font-size: 1rem;
    }
  }
  .create-broadcast {
    width: 95%;
  }
  .Jobs_Detail{
    width: 95%;
  }
  
  .driver-info .input-fields-area .fields-wrapper {
    flex-wrap: wrap;
  }
  .driver-info .input-fields-area .fields-wrapper .form-group {
    width: 100%;
  }
  .driver-info .input-fields-area .fields-wrapper .form-group:nth-child(2) {
    margin-left: 0;
    margin-right: 0;
  }
  .driver-info .input-fields-area .info-btns .btn.btn-purple {
    margin-top: 0;
  }
  .input-fields-area.paymentInfoStep {
    width: 95%;
    padding: 25px;

    & form .b-detail-submit {
      display: block !important; 
      text-align: center;
      & button {
        width: 100%;
        margin-bottom: 15px;
      }

    }
  }
  .filter-hamburger span {
    display: none;
  }
  .my-account .right-tabs .input-fields-area .form-group {
    width: 100%;
  }
  .card.user-card-full .user-profile {
    padding: 0;
  }
  .sidebar.center-popup {
    width: 95%;
  }
  .sidebar.center-popup .model-info {
    padding: 15px;
  }
  .sidebar.center-popup .model-info button {
    margin: 0 5px 10px 5px;
    padding: 12px 28px;
  }
  .input-fields-area .upload-logo .logo-box .upload-wrapper input {
    width: 100%;
  }
  .paymentMethods-sec .input-fields-area .fields-wrapper {
    display: initial;
  }
  .paymentInfoStep.input-fields-area .fields-wrapper .form-group {
    width: 100%;
  }

  .home-page .input-fields-area .fields-wrapper .form-group:has(h4) {
    width: 95%;
  }

  .home-page .right-post-side .news-item .news-text-box {
    padding: 15px;
   }
  .home-page .right-post-side .news-item .news-text-box .job-desc .job-apply button {
   margin-left: 0px;
   display: block;
  }
  .home-page .right-post-side .news-item .news-text-box .job-apply button {
    padding: 10px 25px;
    margin-top: 15px;
   }
  .home-page .news-item .news-text-box {
    padding: 15px;
    & .job-apply button {
      margin-left: 0px;
      padding: 10px 25px;
    }
    & .job-apply a{
      margin-right: 15px;
    }
}
  .home-page .input-fields-area .detail-field-row {
    display: block;
    & label {
      width: 100%;
    }
    & .detail-info {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .home-page .user-jobs-box {
    padding: 20px 10px;

    & button {
      padding: 5px 15px;
    }
    & .notification-body .user--name {
      font-size: 15px;
    }
    & .notification-body .user--country {
      font-size: 15px;
      margin-top: 0px;
    }
    & .notification-body img {
      height: 40px;
      width: 40px;
    }
    
  }
  .create-broadcast .broadcast-body .input-fields-area .fields-wrapper .form-group {
    width: 100%;
  }
  
  .create-broadcast .broadcast-body .input-fields-area .fields-wrapper {
    display: block;
  }
  .create-broadcast .broadcast-body .input-fields-area .driver-detail .detail-field-row{
    display: block;  
  }
  .create-broadcast .broadcast-body .input-fields-area .driver-detail .detail-field-row .detail-info{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
      }

.chat-message p a {
  font-size: 10px;
}

}

